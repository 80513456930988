import _ from 'lodash';
import { getParentIdByPath } from '../../utils';
import workspaceService from '../../services/workspace.service';
import { CATEGORY_TYPES } from '../../constants';
import { hasPermissionsToEditItem } from './iam.selector';
import { getUser } from './user.selector';

export const getByIdCategory = (category, idToSearch) => {
  if (category.children && category.children.length !== 0) {
    let result;
    category.children.forEach(item => {
      if (result) return false;
      let res = getByIdCategory(item, idToSearch);

      if (res) result = res;
    });

    return result;
  }

  if (category.knowledge_item_medias && category.knowledge_item_medias !== 0) {
    return category.knowledge_item_medias.find(({ id }) => idToSearch === id);
  }
};

export const getLabRoot = ({ categories }) => {
  //support orgs with and without workspaces
  if (!workspaceService.hasWorkspaces()) {
    if (!categories) return {};
    return categories.find(category => category.path?.toLowerCase() === 'root');
  }

  const workspaceId = workspaceService.getSelectedWorkspaceId();

  if (!categories || !workspaceId) return {};

  return (
    categories.find(
      category => category.path?.toLowerCase() === 'root' && category.workspaceId === workspaceId,
    ) ?? {}
  );
};

export const getCategoriesByPath = ({ categories, categoriesById }, path) => {
  if (_.isNull(categories)) return null;

  let parentId = getParentIdByPath(path);

  return getLabCategoriesById({ categoriesById }, parentId);
};

export const getLabCategoriesById = ({ categoriesById }, id) => {
  if (_.isNull(categoriesById)) return null;

  if (!categoriesById[id]) return null;

  return categoriesById[id].subcategories.map(i =>
    getLabCategoryById({ categoriesById }, { id: i }),
  );
};

export const getLabCategoryById = ({ categoriesById }, { id }) => {
  return categoriesById[id] || {};
};

export const getItemById = ({ itemsById }, { id }) => {
  return itemsById[id] || null;
};

export const generateBreadcrumbs = ({ categoriesById, categories }, { pathParts = [] }) => {
  const rootId = getLabRoot({ categories })?.id;

  return pathParts
    ? pathParts
        .filter(part => part.toLowerCase() !== 'root' && part !== rootId)
        .map(id => categoriesById[id])
    : [];
};

export const getItemsByCategoryId = ({ itemsById, categoriesById }, { id }) => {
  let { values = [] } = categoriesById[id] || {};

  return values.map(id => itemsById[id] || null).filter(i => !!i);
};

export const getLabParentsByItemId = ({ categories }, { id }) => {
  return categories.filter(i => i.type === 'values' && i.values.includes(id));
};

export const getLocations = ({ categories, categoriesById }, { type = 'category', path }) => {
  const workspaceId = workspaceService.getSelectedWorkspaceId();
  const filteredCategories = categories.filter(category => category?.workspaceId === workspaceId);
  const root = getLabRoot({ filteredCategories });
  let locations = [];

  // check if need to section or category
  if (path.split(',').length % 2 !== 0) {
    locations = filteredCategories.filter(
      i => i.type === type && i.pathParts?.length % 2 === 0 && !!i.path,
    );

    let root = getLabRoot({ filteredCategories });
    locations.unshift({ fullPath: root.id, title: root.title });
  } else {
    locations = filteredCategories.filter(i => i.type === type && i.pathParts?.length % 2 !== 0);
  }

  return locations
    .filter(locations => {
      // filter out locations that have either 'root' or 'root,<id_of_root>' as path
      const rootPathRegex = new RegExp(`^root(,${root.id})?$`);
      return !rootPathRegex.test(locations.path);
    })
    .map(item => {
      let path = generateBreadcrumbs({ categoriesById }, { pathParts: item.pathParts }).map(
        t => t?.title,
      );

      path = [...path, item.title].reverse();

      // Remove lab base root
      if (path.length > 1) {
        path.pop();
      }

      return {
        id: item.fullPath,
        value: path.join(' | '),
      };
    });
};

/**
 * Check if the user is the owner of the category and all of its children
 *
 * @param {ReduxState} state
 * @param {{ id: ObjectId }} { id } Lab entity id
 * @return {Boolean} if the user is the owner of the category and all of its children
 */
export function isCompendiumOwner(state, { id }) {
  if (!id) return false;

  const hasPermissions = hasPermissionsToEditItem(state);

  // Check if the user has permission to bypass the owner restriction
  if (hasPermissions) return true;

  // Get user id
  const { id: userId } = getUser(state);

  return _checkLabChildrenOwnership(state, id, userId);
}

/**
 * Check if the user is the owner of the category and all of its children
 *
 * @param {ReduxState} state
 * @param {ObjectId} id - category id
 * @param {ObjectId} userId - user id
 * @return {Boolean} if the user is the owner of the category and all of its children
 */
function _checkLabChildrenOwnership(state, id, userId) {
  // get category by id
  const item = getLabCategoryById(state.lab, { id });

  // check if the category is owned by the user
  if (!item.ownerIds?.includes(userId)) {
    return false;
  }

  if (item.type === CATEGORY_TYPES.CATEGORY) {
    // recursively check if all of the subcategories are owned by the user
    return item.subcategories.every(subcategoryId => {
      return _checkLabChildrenOwnership(state, subcategoryId, userId);
    });
  }

  return item.values?.every(valId => {
    const value = getItemById(state.lab, { id: valId });
    return value?.ownerIds?.includes(userId);
  });
}

export const isValueOwner = ({ auth }, item) => {
  return item.ownerIds?.includes(auth.user.id);
};
