import { connect } from 'react-redux';
import { DashboardPageComponent } from './Dashboard.component';
import {
  getKnowledgeItemsOpenEventsForOrganization,
  getKnowledgeItemsOpenEventsForUser,
  getExpiredItems,
} from 'shared/store/selectors/knowledge.selector';
import {
  findDefaultScheduleUrl,
  findScheduleByProfessionAndRoles,
  getQuickLinks,
} from 'shared/store/selectors/organization.selector';
import { getOperationalData } from 'shared/store/selectors/workspace.selector';
import { fetchAvailableOrganizations } from 'shared/store/actions/available-organizations.actions';
import { isAccountAdmin } from 'shared/store/selectors/auth.selector';

const mapStateToProps = ({
  knowledge,
  auth,
  owners,
  application,
  lang,
  workspace,
  organization,
}) => {
  const operationalData = getOperationalData({ workspace, organization });

  return {
    lang: lang.DASHBOARD,
    addAccLang: lang.ADD_ACCOUNT,
    expiredItems: getExpiredItems(knowledge, owners) || [],
    itemsOpenEventsForOrganization: getKnowledgeItemsOpenEventsForOrganization(knowledge) || [],
    itemsOpenEventsForUser: getKnowledgeItemsOpenEventsForUser(knowledge) || [],
    isReady: knowledge.isReady,
    adminMode: application.adminMode,
    calendarUrl: operationalData?.calendarUrl,
    scheduleUrl:
      findScheduleByProfessionAndRoles(operationalData?.scheduleUrls, auth?.user) ||
      findDefaultScheduleUrl(operationalData?.scheduleUrls),
    scheduleUrlMap: operationalData?.scheduleUrls,
    isAdmin: isAccountAdmin({ auth }),
    quickLinks: getQuickLinks({ organization }),
  };
};

export const DashboardPage = connect(mapStateToProps, { fetchAvailableOrganizations })(
  DashboardPageComponent,
);
