import React, { useCallback } from 'react';
import {
  KNOWLEDGE_TYPES,
  KNOWLEDGE_ITEM_PREVIEW,
  EVENTS,
  ROUTES,
  FAVORITES_ENTITIES,
  ISSUE_REPORT_ORIGIN,
} from 'shared/constants';
import { TableOfContents } from '../components/table-of-content';
import { notification } from 'antd';
import { useSelector } from 'react-redux';
import { getOwnersByIDs } from 'shared/store/selectors/owners.selector';
import analyticsService from '../../../helpers/analytics.service';
import styles from '../knowledge-content.module.scss';
import { ShareBoxComponent } from '../../share';
import { KnowledgeUploadFileComponent } from '../knowledge-upload-file';
import { LabelsList } from '../../labels';
import { KnowledgeIssueReport } from '../../issue-report';
import { useUpdatedByInfo } from 'shared/hooks/knowledge.hooks';
import { FavoritesButton } from 'src/components/favorites-button';
import { BackTop, Button } from 'src/components/design-system';
import { KnowledgeViewLayout } from '../layout/knowledge-view-layout';
import { KnowledgeViewers } from '../components/knowledge-viewers/knowledge-viewers.component';
import {
  SmartFormatToggle,
  KnowledgeTags,
  RenderKeywords,
  RenderSuggestKeywords,
  RevisionLog,
} from './components';
import { RenderSection } from '../components';
import { RenderBack } from '../components/render-back';
import { useDateFormatter } from 'shared/hooks/dates.hooks';
import { Contributors } from '../components/contributors';
import { getContactItemById } from 'shared/store/selectors/contact.selector';
import { KnowledgeComments, CommentsHeader } from '../components/knowledge-comments';
import _ from 'lodash';
import { haveEditPermissions } from 'shared/store/selectors/knowledge.selector';
import { Tooltip } from 'src/components/core';
import { getLang } from 'shared/store/selectors/lang.selector';
import { KnowledgeCommentsProvider } from '../components/knowledge-comments/hooks';
import { ShareToHub } from 'src/components/share-to-hub';
import { useUpdateKnowledgeItem } from './hooks/use-update-knowledge-item.hooks';
import { formatFullName } from 'shared/utils';
import { useContentPreview } from 'src/pages/application/knowledge-content/hooks';
import hubServiceApi from 'shared/api/hub.service.api';

const ASSETS_URL_REGEX =
  /https:\/\/assets\.(dev\.|staging\.)?(app|eu)\.(headtotoe\.io|c8\.health|c8health\.com)\/|http:\/\/localhost:45(00|55)\//;

const KnowledgeContentPreviewComponent = React.memo(function KnowledgeContentPreviewComponent({
  knowledge: initialKnowledge,
  lang,
  langRevision,
  linked,
  toggleEditMode,
  close,
  setKnowledgeEntityForEditor,
}) {
  const { isHub, item, analyticsTriggers } = useContentPreview();
  let knowledge = initialKnowledge;
  let basePath = ROUTES.KNOWLEDGE_ITEMS;
  let organization = null;

  if (isHub) {
    knowledge = item;
    basePath = ROUTES.KNOWLEDGE_HUB_ITEMS;
    organization = item.organization;
  }

  const {
    id,
    title,
    subtitle,
    ownerIds = [],
    fileUpdatedAt,
    expiresAt,
    keywords,
    tags,
    content,
    link,
    type,
    labels,
    updatedBy,
    updatedAt,
    metadata,
    contributors = [],
    workspaceId,
    hubInfo,
  } = knowledge;

  let owners = useSelector(getOwnersByIDs(ownerIds));

  const hasEditPermission = useSelector(state => haveEditPermissions(state, { ownerIds }));

  const [smartFormatMode, setSmartFormatMode] = React.useState(true);
  const [convertFile, setConvertFile] = React.useState(false);
  const [showIssueReportPopup, setShowIssueReportPopup] = React.useState(false);
  const [showLog, setShowLog] = React.useState(false);
  const [notificationApi, contextHolder] = notification.useNotification();
  const { formatLongDate } = useDateFormatter();

  const actionTooltipLang = useSelector(getLang('ACTION_BUTTON_TOOLTIPS'));

  const allowedToEdit = !linked && !metadata?.lastSyncAt && !isHub;

  const isContent = type === KNOWLEDGE_TYPES.CONTENT;
  const isInternalPDF = type === KNOWLEDGE_TYPES.PDF && !!link?.match(ASSETS_URL_REGEX)?.length;

  const validatedOnDate = formatLongDate(fileUpdatedAt || updatedAt);

  // Get updated by info - if updatedBy is not available, get the last revision log
  let updatedByInfo = useSelector(state => {
    if (_.isObject(updatedBy) && updatedBy?.id) return updatedBy;

    return (
      getContactItemById(state, updatedBy) ||
      (knowledge?.revisionLog && knowledge?.revisionLog?.at(-1))
    );
  });

  if (isHub) {
    updatedByInfo = item.updatedByUser;
    owners = item?.owners ?? [item.owner];
  }

  const { updatedByName, updatedByEmail } = useUpdatedByInfo({
    updatedBy: updatedByInfo,
  });

  const updatedAtDate = formatLongDate(updatedAt);

  const openNotification = useCallback(
    ({ message, type = 'success' }) => {
      notificationApi[type]({
        message,
        placement: 'bottomRight',
      });
    },
    [notificationApi],
  );

  const { setFieldValue } = useUpdateKnowledgeItem({
    item: knowledge,
    openNotification: openNotification,
  });

  const renderLabels = useCallback(() => {
    if (!labels || labels?.length === 0) return null;

    return (
      <RenderSection header={lang.LABELS}>
        <div className={styles.keywords}>
          <LabelsList labels={labels} />
        </div>
      </RenderSection>
    );
  }, [labels, lang]);

  const toggleSmartFormat = useCallback(
    state => {
      analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.TOGGLE_SMART_FORMAT, {
        id: knowledge?.id,
        title: knowledge?.title,
        type: knowledge.type,
        smartFormat: state,
      });

      setSmartFormatMode(state);
    },
    [knowledge],
  );

  const renderToggleFormat = () => {
    if (!isContent || isHub) {
      return null;
    }
    return (
      <RenderSection>
        <SmartFormatToggle {...{ smartFormatMode, toggleSmartFormat, link }} />
      </RenderSection>
    );
  };

  const toggleEdit = () => {
    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.EDIT_ITEM, {
      id: knowledge?.id,
      title: knowledge?.title,
      ownerIds: knowledge.ownerIds,
      type: knowledge.type,
      ...knowledge.tags,
    });

    toggleEditMode();
  };

  const cloneFromHub = async () => {
    const { data, error } = await hubServiceApi.prepareClone({ id });

    if (error || !data) {
      openNotification({ message: lang.ERROR });
      return;
    }

    setKnowledgeEntityForEditor(data);

    analyticsTriggers?.onImport({
      id: knowledge?.id,
      title: knowledge?.title,
      type: knowledge.type,
      ownerIds: knowledge.ownerIds,
      ...knowledge.tags,
      sourceOrganizationName: organization?.name,
      sourceOrganizationHealthSystem: organization?.healthSystem,
      sourceOrganizationDepartment: organization?.department,
    });
  };

  const convertFileToSmartFormat = () => {
    if (!isInternalPDF) return;
    setConvertFile(true);
  };

  const closeDirectConversion = () => {
    setConvertFile(false);
  };

  const openIssueReportPopup = () => {
    setShowIssueReportPopup(true);
  };
  const closeIssueReportPopup = () => {
    setShowIssueReportPopup(false);
  };

  const openLog = () => setShowLog(true);

  const closeLog = () => setShowLog(false);

  const renderKnowledgeActions = () => {
    return (
      <section className={styles.knowledgeActions} style={isHub ? { borderTop: 'none' } : {}}>
        {allowedToEdit && (
          <>
            <Tooltip
              title={hasEditPermission ? null : actionTooltipLang.NOT_ALLOWED_TO_EDIT}
              placement="top"
            >
              <Button
                className={styles.headerActionsButton}
                type="primary"
                onClick={toggleEdit}
                disabled={!hasEditPermission}
              >
                {lang.EDIT}
              </Button>
            </Tooltip>
            {isInternalPDF && hasEditPermission && (
              <Button
                className={styles.headerActionsButton}
                type="primary"
                ghost
                onClick={convertFileToSmartFormat}
              >
                {lang.CONVERT}
              </Button>
            )}
          </>
        )}
        {isHub && (
          <Button className={styles.headerActionsButton} type="primary" onClick={cloneFromHub}>
            {lang.IMPORT_FROM_HUB}
          </Button>
        )}
        <Button
          className={styles.headerActionsButton}
          type="primary"
          ghost
          onClick={openIssueReportPopup}
        >
          {lang.REPORT_PROBLEM}
        </Button>

        {!isHub && hasEditPermission && (
          <ShareToHub
            name="shareToHub"
            value={knowledge?.shared?.isShared}
            setFieldValue={setFieldValue}
            topBorder
          />
        )}
      </section>
    );
  };

  const renderTableOfContents = () => {
    return <TableOfContents itemId={knowledge?.id} />;
  };

  const renderOwner = () => {
    if (!owners.length) return null;

    let hubOrganizationSource = null;

    if (organization) {
      hubOrganizationSource = organization.department
        ? ` ${lang.DEPARTMENT} ${organization?.department}, ${organization?.healthSystem} `
        : organization.name;
    }

    return (
      <>
        {lang.VALIDATED_BY}
        {owners.map((owner, index) => (
          <>
            {index > 0 && ', '}
            <a
              className={index < owners.length - 1 ? styles.ownerLink : ''}
              href={`mailto:${owner?.email}`}
              target="_blank"
              rel="noreferrer"
            >
              {formatFullName(owner)}
            </a>
          </>
        ))}
        {hubOrganizationSource ? <div>{`,`}</div> : null}
        <div className={styles.bold}>{hubOrganizationSource}</div>
        {lang.ON_DATE.replace('{date}', validatedOnDate)}
      </>
    );
  };

  const renderHubInfo = () => {
    if (isHub || !hubInfo) return null;

    const { owners, organization } = hubInfo;

    const hubOrganizationSource = organization.department
      ? ` ${lang.DEPARTMENT} ${organization?.department}, ${organization?.healthSystem} `
      : organization.name;

    return (
      <>
        {' | '}
        {lang.ADAPTED}
        {owners?.map((owner, index) => (
          <>
            {index > 0 && ', '}
            <a
              className={index < owners.length - 1 ? styles.ownerLink : ''}
              href={`mailto:${owner?.email}`}
              target="_blank"
              rel="noreferrer"
            >
              {formatFullName(owner)}
            </a>
          </>
        ))}
        <div className={styles.bold}>{hubOrganizationSource}</div>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <div className={styles.contentHeader}>
        <div className={styles.iconsWrapper}>
          <ShareBoxComponent
            path={basePath.replace(':id', id)}
            id={id}
            title={title}
            tags={tags}
            type={type}
            entityType={KNOWLEDGE_ITEM_PREVIEW}
            iconClassName={styles.shareIcon}
          />
          {!isHub && (
            <FavoritesButton
              id={id}
              entity={FAVORITES_ENTITIES.KNOWLEDGE}
              className={styles.bookmarkIcon}
              wrapper={styles.bookmarkWrapper}
            />
          )}
        </div>
        <div className={styles.header}>
          <RenderBack close={close} />
          <h1 className={styles.title}>{title}</h1>
        </div>
        {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
        <div className={styles.metadata}>
          {renderOwner()}
          {renderHubInfo()}
          {updatedBy && (
            <>
              {' | '}
              {lang.UPDATED_BY}
              <a href={`mailto:${updatedByEmail}`} target="_blank" rel="noreferrer">
                {updatedByName}
              </a>
              {lang.ON_DATE.replace('{date}', updatedAtDate)}
            </>
          )}
          <div style={{ marginLeft: '0.2rem' }}>
            <Contributors contributors={contributors} subtitleSeparator=" | " />
          </div>
        </div>
      </div>
    );
  };

  const renderRightPanel = () => {
    return (
      <>
        {!isHub && <CommentsHeader />}

        {renderToggleFormat()}

        {renderKnowledgeActions()}

        {updatedBy && (
          <RenderSection header={lang.UPDATED_BY}>
            <div className={styles.updatedBy}>
              <a href={`mailto:${updatedByEmail}`} target="_blank" rel="noreferrer">
                {updatedByName}
              </a>
              <h4>{lang.ON_DATE.replace('{date}', updatedAtDate)}</h4>
            </div>
          </RenderSection>
        )}

        <KnowledgeTags tags={tags} workspaceId={workspaceId} />

        {renderLabels()}

        <RenderSection header={lang.EXPIRATION_DATE}>
          <p className={styles.content}>{formatLongDate(expiresAt)}</p>
        </RenderSection>

        <RenderKeywords keywords={keywords} />

        {!isHub && <RenderSuggestKeywords {...{ id, keywords, openNotification }} />}

        {!isHub && (
          <RenderSection>
            <span>
              {langRevision.VIEW_REVISION}
              <button className={styles.link} onClick={openLog}>
                {langRevision.TITLE}
              </button>
            </span>
          </RenderSection>
        )}
      </>
    );
  };

  return (
    <>
      <KnowledgeCommentsProvider knowledgeId={id}>
        <KnowledgeViewLayout
          hideLeftPanel={!(smartFormatMode && knowledge?.content)}
          renderLeftPanel={renderTableOfContents}
          renderHeader={renderHeader}
          renderRightPanel={renderRightPanel}
          renderFooter={() => !isHub && <KnowledgeComments />}
        >
          {!showLog && (
            <KnowledgeViewers {...{ id, title, link, content, type, isContent, smartFormatMode }} />
          )}
          {showLog && (
            <RevisionLog
              close={close}
              closeLog={closeLog}
              log={knowledge.revisionLog}
              lang={langRevision}
              link={link}
            />
          )}
          <BackTop
            className={styles.backTop}
            type="primary"
            target={() => document.querySelector('#full-width')}
          />
        </KnowledgeViewLayout>
        {convertFile && (
          <KnowledgeUploadFileComponent
            {...knowledge}
            convertPastItemToSmartFormat={convertFile}
            editContent={setKnowledgeEntityForEditor}
            close={closeDirectConversion}
          />
        )}
        {showIssueReportPopup && (
          <KnowledgeIssueReport
            close={closeIssueReportPopup}
            notificationApi={notificationApi}
            itemId={id}
            origin={isHub ? ISSUE_REPORT_ORIGIN.KNOWLEDGE_HUB : ISSUE_REPORT_ORIGIN.KNOWLEDGE_BASE}
          />
        )}
      </KnowledgeCommentsProvider>

      {contextHolder}
    </>
  );
});

KnowledgeContentPreviewComponent.defaultProps = {
  knowledge: {
    title: '',
    subtitle: '',
    thumbnail: '',
    owners: [],
    updatedAt: '',
    fileUpdatedAt: '',
    expiresAt: '',
    keywords: [],
    tags: {},
    content: '',
    link: '',
  },
  lang: {},
  langTags: {},
  langTagsForm: {},
  type: KNOWLEDGE_TYPES.LINK,
  linked: false,
  toggleEditMode: () => {},
  close: () => {},
};

export { KnowledgeContentPreviewComponent };
