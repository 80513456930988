import React from 'react';
import _ from 'lodash';
import styles from './Favorites.module.scss';
import { Lang, FAVORITES_ENTITIES } from 'shared/constants';
import { KnowledgeWrapperComponent } from '../knowledge/components/knowledge-row';
import { PandaHappy } from '../../../images';
import { ContactItemComponent } from '../../../components/contact-item/contact-item.component';

const FavoritesPageComponent = React.memo(({ lang, list }) => {
  const renderItems = () => {
    if (_.isNull(list) || list.length === 0) {
      return (
        <div className={styles.descriptionWrapper}>
          <img src={PandaHappy} className={styles.doctor} alt={lang.HEADER} />
          <h4>{lang.CONTENT}</h4>
        </div>
      );
    }

    const contacts = list
      .filter(
        item =>
          item.entity === FAVORITES_ENTITIES.CONTACT || item.entity === FAVORITES_ENTITIES.USER,
      )
      .sort(
        (a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName),
      )
      .map(item => <ContactItemComponent key={item.id} contact={item} />);

    const knowledge = list
      .filter(item => item.entity === FAVORITES_ENTITIES.KNOWLEDGE)
      .map(item => <KnowledgeWrapperComponent key={item.id} item={item} light showSpecialty />);

    return (
      <div className={styles.items}>
        <h2>{lang.HEADER}</h2>
        {contacts}
        {knowledge}
      </div>
    );
  };

  return <div className={styles.root}>{renderItems()}</div>;
});

FavoritesPageComponent.defaultProps = {
  lang: Lang.FAVORITES_SCREEN,
  list: null,
  lastUpdated: null,
};

export { FavoritesPageComponent };
