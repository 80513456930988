import React, { useState, useEffect } from 'react';
import {
  DeleteIcon,
  DirectoryIcon,
  DragIcon,
  InformationIcon,
  ItemBinderIcon,
  LinkIcon,
  DocumentIcon,
  ImageIcon,
  PresentationIcon,
  VideoIcon,
  WebsiteLinkIcon,
} from 'src/images';
import styles from './knowledge.module.scss';
import classnames from 'classnames';
import { FavoritesButton } from 'src/components/favorites-button';
import { UpdatedIndication } from 'src/components/updatedIndication';
import { useDrag, useDrop } from 'react-dnd';
import { SingleTagIcon } from 'src/components/tags/tagsIcons';
import analyticsService from 'src/helpers/analytics.service';
import {
  EVENTS,
  KNOWLEDGE_TYPES,
  KNOWLEDGE_ITEM,
  DATA_KEYS,
  Lang,
  ROUTES,
  FAVORITES_ENTITIES,
  ENTITY_TYPES,
} from 'shared/constants';
import { Input } from 'antd';
import { Tooltip } from 'src/components/core';
import { useHistory } from 'react-router';
import { ShareBoxComponent } from 'src/components/share';
import { isVideoUrl } from 'shared/utils';
import { ExtendExpirationButtons } from 'src/components/knowledge-content/knowledge-content-editor/extend-expiration-buttons.component';
import { LabelsList } from 'src/components/labels';
import { useSelector } from 'react-redux';
import { haveEditPermissions } from 'shared/store/selectors/knowledge.selector';

const { TextArea } = Input;

const TYPE_TO_ICON_MAPPER = {
  content: DocumentIcon,
  pdf: DocumentIcon,
  excel: DocumentIcon,
  document: DocumentIcon,
  image: ImageIcon,
  video: VideoIcon,
  presentation: PresentationIcon,
  link: LinkIcon,
  'external-link': WebsiteLinkIcon,
};

const Knowledge = React.memo(function Knowledge({
  id,
  title,
  subtitle,
  link,
  thumbnail,
  ownerIds,
  itemUpdatedAt,
  light,
  adminMode,
  deleteKnowledge,
  moveKnowledgeAction,
  dragItem,
  findItem,
  dndGroup,
  draggable,
  finishDrag,
  tags,
  showSpecialty,
  actionButtons,
  openInformation,
  linked,
  externalUpdateAction,
  lang,
  type,
  expiresAt,
  allowEditExpired,
  showLabels = false,
  labels,
  onClickEvent = () => {},
  hideFavorites = false,
  hideInfo = false,
  basePath = ROUTES.KNOWLEDGE_ITEMS,
}) {
  let boxRef = React.useRef(null);
  const history = useHistory();

  const hasEditPermission = useSelector(state => haveEditPermissions(state, { ownerIds }));

  const [titleInput, setTitleInput] = useState(title);
  const [subtitleInput, setSubtitleInput] = useState(subtitle);

  useEffect(() => {
    if (titleInput !== title) {
      setTitleInput(title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, setTitleInput]);

  useEffect(() => {
    if (subtitleInput !== subtitle) {
      setSubtitleInput(subtitle);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtitle, setSubtitleInput]);

  const originalIndex = findItem(id)?.index;

  const [{ isDragging }, drag] = useDrag({
    item: { type: dndGroup, id, originalIndex },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => adminMode && draggable,
    end: (dropResult, monitor) => {
      const { id: droppedId, originalIndex } = monitor.getItem();
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        dragItem(droppedId, originalIndex);
      }

      finishDrag(droppedId);
    },
  });
  const [, drop] = useDrop({
    accept: dndGroup,
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findItem(id);
        dragItem(draggedId, overIndex);
      }
    },
  });

  if (draggable) {
    boxRef = drag(drop(boxRef));
  }

  const onClick = () => {
    const event = {
      id: id.toString(),
      title,
      ownerIds,
      type,
      ...tags,
    };

    // Trigger event
    onClickEvent(event);

    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.OPEN, event);
    analyticsService.track(EVENTS.CONTENT.OPEN, {
      ...event,
      entityType: ENTITY_TYPES.knowledge,
    });

    history.push(basePath.replace(':id', id));
  };

  const openInformationInner = React.useCallback(
    e => {
      e.stopPropagation();
      openInformation(e);

      analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.OPEN_INFORMATION, {
        id: id.toString(),
        title,
        ownerIds,
        type,
        ...tags,
      });
    },
    [id, tags, title, ownerIds, type, openInformation],
  );

  const deleteKnowledgeItem = React.useCallback(
    e => {
      e.stopPropagation();

      deleteKnowledge({ id, title });
    },
    [deleteKnowledge, id, title],
  );

  const changeDirectory = React.useCallback(
    e => {
      e.stopPropagation();

      moveKnowledgeAction({ id, title, ownerIds, thumbnail });
    },
    [id, moveKnowledgeAction, title, ownerIds, thumbnail],
  );

  const onInputFocus = React.useCallback(e => {
    e.stopPropagation();
  }, []);

  const onPress = React.useCallback(e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      // TODO: add analytics
      e.target.blur();
    }
  }, []);

  const onBlur = React.useCallback(
    async e => {
      let { value, name: field } = e.target;
      // TODO: add analytics
      if ((field === 'title' && value === title) || (field === 'subtitle' && value === subtitle)) {
        return false;
      }
      await externalUpdateAction({ [field]: value });
    },
    [title, subtitle, externalUpdateAction],
  );

  const onChange = React.useCallback(
    e => {
      if (e.target.name === 'title') {
        setTitleInput(e.target.value);
      }
      if (e.target.name === 'subtitle') {
        setSubtitleInput(e.target.value);
      }
    },
    [setTitleInput, setSubtitleInput],
  );

  const renderThumbnail = () => {
    if (!thumbnail) {
      const isVideo = isVideoUrl(link);
      const Icon = isVideo
        ? TYPE_TO_ICON_MAPPER[KNOWLEDGE_TYPES.VIDEO]
        : TYPE_TO_ICON_MAPPER[type] || ItemBinderIcon;

      return <img src={Icon} className={styles.itemIcon} alt={`${type}-knowledge-item`} />;
    }

    return (
      <div className={styles.thumbnailWrapper}>
        <img src={thumbnail} alt={title} className={styles.thumbnail} />
      </div>
    );
  };

  const renderTagIcon = () => {
    let tagToRender = showSpecialty ? tags?.specialty : null;
    if (!tagToRender) return null;

    return <SingleTagIcon tag={tagToRender} darkBackground={!showSpecialty} />;
  };

  const renderActions = () => {
    const knowledgeItemLink = basePath.replace(':id', id);

    const renderShareBox = () => {
      return (
        <button type="button" className={styles.buttonWrapper} onClick={e => e.stopPropagation()}>
          <ShareBoxComponent
            path={knowledgeItemLink}
            iconClassName={styles.actionIcon}
            id={id}
            title={title}
            tags={tags}
            type={type}
            entityType={KNOWLEDGE_ITEM}
          />
        </button>
      );
    };

    if (!adminMode) {
      return (
        <>
          <UpdatedIndication lastUpdate={itemUpdatedAt} />
          <div className={styles.buttons}>
            {renderTagIcon()}
            {renderShareBox()}
            {!hideInfo && (
              <Tooltip title={lang.INFO}>
                <button
                  data-key={DATA_KEYS.KNOWLEDGE_BASE.INFO}
                  type="button"
                  onClick={openInformationInner}
                  className={styles.buttonWrapper}
                >
                  <InformationIcon className={styles.informationIcon} />
                </button>
              </Tooltip>
            )}
            {!hideFavorites && (
              <FavoritesButton
                id={id}
                className={styles.actionIcon}
                wrapper={styles.buttonWrapper}
                entity={FAVORITES_ENTITIES.KNOWLEDGE}
              />
            )}
          </div>
        </>
      );
    }

    return (
      <>
        <div className={styles.buttons}>
          {actionButtons ? (
            <>
              {renderShareBox()}
              {actionButtons()}
            </>
          ) : (
            <>
              <Tooltip title={lang.DELETE}>
                <button
                  data-key={DATA_KEYS.KNOWLEDGE_BASE.DELETE}
                  type="button"
                  onClick={deleteKnowledgeItem}
                  className={styles.buttonWrapper}
                >
                  <DeleteIcon className={styles.deleteIcon} />
                </button>
              </Tooltip>
              {!!moveKnowledgeAction && (
                <Tooltip title={lang.MOVE}>
                  <button type="button" onClick={changeDirectory} className={styles.buttonWrapper}>
                    <DirectoryIcon className={styles.changeDirectory} />
                  </button>
                </Tooltip>
              )}
            </>
          )}
          {draggable && (
            <Tooltip title={lang.DRAG}>
              <button
                type="button"
                className={classnames(styles.buttonWrapper, styles.dragIconWrapper)}
              >
                <DragIcon className={styles.dragIcon} />
              </button>
            </Tooltip>
          )}
        </div>
      </>
    );
  };

  const renderText = () => {
    if (adminMode && !linked) {
      return (
        <>
          <TextArea
            onClick={onInputFocus}
            onKeyPress={onPress}
            onBlur={onBlur}
            className={styles.titleTextarea}
            autoSize={{ minRows: 1 }}
            disabled={!adminMode || !hasEditPermission}
            value={titleInput}
            onChange={onChange}
            name="title"
          />
          <TextArea
            onClick={onInputFocus}
            onKeyPress={onPress}
            onBlur={onBlur}
            className={styles.subtitleTextarea}
            autoSize={{ minRows: 1 }}
            disabled={!adminMode || !hasEditPermission}
            value={subtitleInput}
            onChange={onChange}
            name="subtitle"
          />
        </>
      );
    }

    return (
      <>
        <h4>{title}</h4>
        <div>
          {showLabels && <LabelsList labels={labels} />}
          <span className={styles.subtitle}>{subtitle}</span>
        </div>
      </>
    );
  };

  const extendExpirationAction = async value => {
    await externalUpdateAction({ expiresAt: value });
  };

  let opacity = isDragging ? 0 : 1;
  return (
    <>
      <div
        ref={boxRef}
        style={{ opacity }}
        className={classnames(styles.root, light ? styles.light : {})}
        onClick={onClick}
      >
        {renderThumbnail()}
        <div className={styles.content}>
          {adminMode && linked && (
            <img src={LinkIcon} className={styles.linkIcon} alt="Linked item" />
          )}
          {renderText()}
        </div>

        {renderActions()}
      </div>
      {allowEditExpired && (
        <ExtendExpirationButtons
          expiresAt={expiresAt}
          onExtendExpiration={extendExpirationAction}
          showExpirationMessage
          showToastMessage
        />
      )}
    </>
  );
});

Knowledge.defaultProps = {
  title: '',
  subtitle: '',
  thumbnail: '',
  itemUpdatedAt: null,
  link: '',
  ownerIds: [],
  light: false,
  adminMode: false,
  draggable: true,
  deleteKnowledge: () => {},
  editKnowledge: () => {},
  dragItem: () => {},
  findItem: () => {},
  dndGroup: '',
  finishDrag: () => {},
  moveKnowledgeAction: null,
  showSpecialty: false,
  actionButtons: null,
  openInformation: () => {},
  linked: false,
  showLabels: false,
  externalUpdateAction: () => {},
  lang: Lang.ACTION_BUTTON_TOOLTIPS,
  content: '',
  type: KNOWLEDGE_TYPES.LINK,
};

export { Knowledge };
