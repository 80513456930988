import React, { useCallback } from 'react';
import { Popup } from '../common';
import { PopupFormButtons } from '../common/popup-form-buttons';

import styles from './knowledge-upload-file/knowledge-upload-file.module.scss';
import { useSelector } from 'react-redux';
import { KNOWLEDGE_TYPES } from 'shared/constants/knowledge.constants';
import { getLang } from 'shared/store/selectors/lang.selector';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { TextInput } from '../design-system';

const EXTERNAL_LINK_SCHEME = Yup.object().shape({
  link: Yup.string().url().required(),
});

const KnowledgeUploadExternalLinkComponent = React.memo(
  function KnowledgeUploadExternalLinkComponent({ close, editExternal, ...props }) {
    const lang = useSelector(getLang('KNOWLEDGE_UPLOAD_POPUP'));
    const langEditor = useSelector(getLang('KNOWLEDGE_EDITOR'));

    const onSubmit = useCallback(
      (values, { resetForm }) => {
        editExternal({ ...props, link: values.link, type: KNOWLEDGE_TYPES.EXTERNAL_LINK });
        resetForm();
        close();
      },
      [close, props, editExternal],
    );

    const { handleSubmit, setFieldValue, values, errors, isValid, dirty } = useFormik({
      initialValues: {
        link: '',
      },
      validationSchema: EXTERNAL_LINK_SCHEME,
      onSubmit: onSubmit,
    });

    const handleLinkChange = useCallback(
      e => {
        const value = e.target.value;
        // Manually replace spaces with %20
        const transformedValue = value.trim().replace(/\s/g, '%20');
        setFieldValue('link', transformedValue);
      },
      [setFieldValue],
    );

    return (
      <Popup isOpen close={close}>
        <form onSubmit={handleSubmit}>
          <h4 className={styles.title}>{lang.TITLE}</h4>

          <TextInput
            name="link"
            value={values.link}
            error={errors.link}
            placeholder={langEditor.LINK_PLACEHOLDER}
            label={langEditor.LINK}
            onChange={handleLinkChange}
            size="small"
          />

          <PopupFormButtons
            dismiss={close}
            disableUpdate={!isValid || !dirty}
            language={{
              DISMISS: lang.DISMISS,
              CREATE_OR_UPDATE: lang.SUBMIT,
            }}
          />
        </form>
      </Popup>
    );
  },
);

KnowledgeUploadExternalLinkComponent.defaultProps = {
  close: () => {},
  editContent: () => {},
  editExternal: () => {},
};

export { KnowledgeUploadExternalLinkComponent };
