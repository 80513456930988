import React, { useCallback, useMemo, useState } from 'react';
import { Select } from 'src/components/design-system/forms';
import { Button } from 'src/components/design-system';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import { formatFullName, sortByAlphabet } from 'shared/utils';
import _ from 'lodash';
import { getOwnersListForEditor, getOwnersByIDs } from 'shared/store/selectors/owners.selector';
import { getLang } from 'shared/store/selectors/lang.selector';
import { isAccountAdmin } from 'shared/store/selectors/auth.selector';
import { OwnerEditor } from 'src/components/ownerSelector/owner-editor';

export function MultipleOwnersSelector({ onChange, ...props }) {
  const ownersList = useSelector(({ owners }) => getOwnersListForEditor(owners));
  const currentOwners = useSelector(getOwnersByIDs(props.value || []));
  const lang = useSelector(getLang('OWNER_SELECTOR'));
  const canAddOwner = useSelector(isAccountAdmin);
  const [modalOpen, setModalOpen] = useState(false);

  const openCreateOwner = () => {
    setModalOpen(true);
    // TODO: analytics
  };

  const closeCreateOwner = () => {
    setModalOpen(false);
  };

  const selectedOwners = useMemo(
    () =>
      currentOwners.map(owner => ({
        value: owner.id,
        label: formatFullName(owner),
      })) || [],
    [currentOwners],
  );

  const options = useMemo(() => {
    // Create a Set of unique identifiers for selected owners
    const selectedSet = new Set(selectedOwners.map(selected => selected.id));

    return (
      ownersList
        // Filter out the selected owners from all options
        .filter(({ id = '' }) => id && !selectedSet.has(id))
        .map(owner => ({
          value: owner.id,
          label: owner.value,
        }))
        .sort((a, b) => sortByAlphabet(a?.label, b?.label))
    );
  }, [ownersList, selectedOwners]);

  const onChangeInternal = useCallback(
    (name, currentValues) => {
      onChange(
        name,
        currentValues.filter(value => !_.isUndefined(value)),
      );
    },
    [onChange],
  );

  const handleOnNewOwner = useCallback(
    (name, value) => {
      onChange('ownerIds', [...props.value, value]);
    },
    [onChange, props.value],
  );

  return (
    <>
      {!modalOpen && (
        <Select
          mode="multiple"
          name="ownerIds"
          options={options}
          bordered={false}
          {...props}
          onChange={onChangeInternal}
          value={selectedOwners}
          dropdownRender={menu => (
            <div>
              {menu}
              {canAddOwner && (
                <>
                  <Divider style={{ margin: '4px 0' }} />
                  <Button style={{ margin: '3px 7px 7px' }} size="small" onClick={openCreateOwner}>
                    {lang.ADD_NEW_OWNER}
                  </Button>
                </>
              )}
            </div>
          )}
        />
      )}

      <OwnerEditor
        isOpen={modalOpen}
        onFinish={closeCreateOwner}
        selectOwnerId={handleOnNewOwner}
      />
    </>
  );
}
